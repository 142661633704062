import React from "react";
import './Events.css';





function Events(){
    return(
        <section id='events'>
        <h2> Troop Outings</h2>
            <div id='special-dates'>
            {/* <h4>**Summer Camp MEDICAL FORMS DUE July 1st!!!**</h4> */}
                 <p>No Troop Outings at this time.</p> 
            </div>
    </section>
    );
}
export default Events;


export const EaglescoutData = [
                  { year: 1969, name: 'David E.'},
                  { year: 1969, name: 'Richard V.'},
                  { year: 1969, name: 'Kenneth W.'},
                  { year: 1970, name: 'Mark H.'},
                  { year: 1973, name: 'Lane Scr.'},
                  { year: 1973, name: 'Lane Sch.'},
                  { year: 1978, name: 'Geoffrey G.'},
                  { year: 1980, name: 'Robert E.'},
                  { year: 1991, name: 'John P.'},
                  { year: 1993, name: 'Benedict R.'},
                  { year: 1993, name: 'Jason D.'},
                  { year: 1998, name: 'Jared G.'},
                  { year: 1998, name: 'Jay E.'},
                  { year: 2000, name: 'Caleb H.'},
                  { year: 2001, name: 'Thomas F.'},
                  { year: 2002, name: 'Jessie C.'},
                  { year: 2003, name: 'Kevin G.'},
                  { year: 2004, name: 'Nghia L.'},
                  { year: 2005, name: 'Joshua D.'},
                  { year: 2005, name: 'Andrew Ku.'},
                  { year: 2006, name: 'Andrew Ko.'},
                  { year: 2008, name: 'Jacob B.'},
                  { year: 2012, name: 'Mitchell B.'},
                  { year: 2012, name: 'Anthony P.'},
                  { year: 2013, name: 'Joseph P.'},
                  { year: 2015, name: 'Anthony A.'},
                  { year: 2016, name: 'John M'},
                  { year: 2017, name: 'Evan P.'},
                  { year: 2017, name: 'Tyler B.'},
                  { year: 2021, name: 'Nathan W.'},
                  { year: 2021, name: 'Steven E.'},
                  { year: 2021, name: 'Joe M.'},
                  { year: 2021, name: 'Owen W.'},
                  { year: 2021, name: 'John V.'},
                  { year: 2022, name: 'Will H.'}
];

import React from "react";
import './Events.css';





function PLC() {
    return (
        <section id='plc__events'>
            <h2> PLC Meetings</h2>
            <div id="plc_dates">
                <div id='troop-dates'>
                        {/* <h5 className='dates'> PLC October 9th, 2022 6:00 - 6:30 PM
                        <br />
                        <p className="house">Mr. Pucel's House</p></h5> */}

                </div>
            </div>
        </section>
    );
}
export default PLC;
import React from "react";
import Footer from "../../components/footer/footer";
import Header from "../../components/Header/Header";
import "./Calendar.css";

function Calendar() {
  return (
    <>
      <Header />
      <div className="calendar_page">
        <h1>calendar test</h1>
      </div>
      <Footer />
    </>
  );
}
export default Calendar;

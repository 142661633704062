import React from "react";
// import { Link } from "react-router-dom";
import "./Events.css";

function SigEvent() {
  return (
    <section id="significant__events">
      <h2>Significant Events</h2>
      <div id="significant-dates">
      <p>No Significant Events at this time.</p>
      </div>
    </section>
  );
}
export default SigEvent;
